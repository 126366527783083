import { createStore } from 'vuex'
import utils from '../services/utils'
import api from '../api/index'

// Create a new store instance.
export const store = createStore({
  state() {
    return {
      drivewayTemplates: [],
      currentDrivewayTemplate: null,

      gradingTemplates: [],
      currentGradingTemplate: null,

      drainageTemplates: [],
      currentDrainageTemplate: null,

      isCreating: true,
      isLoading:false,
    }
  },
  getters: {
    getDrivewayTemplates(state) {
      return state.drivewayTemplates;
    },
    getGradingTemplates(state) {
      return state.gradingTemplates;
    },
    getIsLoading(state){
      return state.isLoading;
    },
  },
  mutations: {
    setIsLoading(state,value){
      state.isLoading = value;
    },
   
    //driveway
    setDrivewayTemplates(state, value) {
      state.drivewayTemplates = value
    },
    setDrivewayTemplateToEdit(state, value) {
      if (!value) {
        state.currentDrivewayTemplate = null
        return
      }
      state.isCreating = false;
      //create a copy not to affect the existing one
      state.currentDrivewayTemplate = {
        id: value.id,
        name: value.name,
        frontDriveway: {
          standardMaxSlope: value.frontDriveway.standardMaxSlope,
          frontSlopes: {
            aboveSlopeInfo: utils.cloneAdvancedSlope(value.frontDriveway.frontSlopes.aboveSlopeInfo),
            middleSlopeInfo: utils.cloneAdvancedSlope(value.frontDriveway.frontSlopes.middleSlopeInfo),
            belowSlopeInfo: utils.cloneAdvancedSlope(value.frontDriveway.frontSlopes.belowSlopeInfo),
          }
        },
        sideDriveway: {
          sideDrivewayTopSlope: utils.cloneSlope(value.sideDriveway.sideDrivewayTopSlope),
        },
      }
    },
    setDrivewayTemplateToCreate(state, value) {
      if (!value) {
        state.currentDrivewayTemplate = null
        return
      }

      state.isCreating = true;
      state.currentDrivewayTemplate = value
    },

    //grading
    setGradingTemplates(state, value) {
      state.gradingTemplates = value
    },
    setGradingTemplateToEdit(state, value) {
      if (!value) {
        state.currentGradingTemplate = null
        return
      }
      state.isCreating = false;
      //create a copy not to affect the existing one
      state.currentGradingTemplate = {
        id: value.id,
        name: value.name,
        wrappingRearCorners: value.wrappingRearCorners,
        minFrontExposedWall: value.minFrontExposedWall,
        maxFrontExposedWall: value.maxFrontExposedWall,
        minRearExposedWall: value.minRearExposedWall,
        maxRearExposedWall: value.maxRearExposedWall,
        rearExposedMaxSlope: value.rearExposedMaxSlope,
        frontLeftCornerSlope: utils.cloneSlope(value.frontLeftCornerSlope),
        rearLeftCornerSlope: utils.cloneSlope(value.rearLeftCornerSlope),
        frontRightCornerSlope: utils.cloneSlope(value.frontRightCornerSlope),
        rearRightCornerSlope: utils.cloneSlope(value.rearRightCornerSlope),
        buffers: {
          sideBuffer: value.buffers.sideBuffer,
          frontBuffer: value.buffers.frontBuffer,
          rearBuffer: value.buffers.rearBuffer,
          drivewayBuffer: value.buffers.drivewayBuffer,
        },
        gradingPoints: {
          rightBelowAngle: utils.cloneSlope(value.gradingPoints.rightBelowAngle),
          leftBelowAngle: utils.cloneSlope(value.gradingPoints.leftBelowAngle),
          rearBelowAngle: utils.cloneSlope(value.gradingPoints.rearBelowAngle),
          rearBufferAngle: utils.cloneSlope(value.gradingPoints.rearBufferAngle),
          rearRightBufferAboveAngle: utils.cloneSlope(value.gradingPoints.rearRightBufferAboveAngle),
          rearRightBufferBelowAngle: utils.cloneSlope(value.gradingPoints.rearRightBufferBelowAngle),
          rearLeftBufferAboveAngle: utils.cloneSlope(value.gradingPoints.rearLeftBufferAboveAngle),
          rearLeftBufferBelowAngle: utils.cloneSlope(value.gradingPoints.rearLeftBufferBelowAngle),
          rearRightCornerAboveAngle: utils.cloneSlope(value.gradingPoints.rearRightCornerAboveAngle),
          rearRightCornerBelowAngle:utils.cloneSlope(value.gradingPoints.rearRightCornerBelowAngle),
          rearLeftCornerAboveAngle: utils.cloneSlope(value.gradingPoints.rearLeftCornerAboveAngle),
          rearLeftCornerBelowAngle: utils.cloneSlope(value.gradingPoints.rearLeftCornerBelowAngle),
          frontRightBelowAngle: utils.cloneSlope(value.gradingPoints.frontRightBelowAngle),
          frontLeftBelowAngle: utils.cloneSlope(value.gradingPoints.frontLeftBelowAngle),
        },
      }
    },
    setGradingTemplateToCreate(state, value) {
      if (!value) {
        state.currentGradingTemplate = null
        return
      }

      state.isCreating = true;
      state.currentGradingTemplate = value
    },

    //drainage
    setDrainageTemplates(state, value) {
      state.drainageTemplates = value
    },
    setDrainageTemplateToEdit(state, value) {
      if (!value) {
        state.currentDrainageTemplate = null
        return
      }
      state.isCreating = false;
      //create a copy not to affect the existing one
      state.currentDrainageTemplate = {
        id: value.id,
        name: value.name,
        leftSide: {
          type: value.leftSide.type,
          frontToBackSlope:utils.cloneSlope(value.leftSide.frontToBackSlope),
          backToFrontSlope:utils.cloneSlope(value.leftSide.backToFrontSlope)
        },
        rightSide: {
          type: value.rightSide.type,
          frontToBackSlope:utils.cloneSlope(value.rightSide.frontToBackSlope),
          backToFrontSlope:utils.cloneSlope(value.rightSide.backToFrontSlope)
        },
      }
    },
    setDrainageTemplateToCreate(state, value) {
      if (!value) {
        state.currentDrainageTemplate = null
        return
      }

      state.isCreating = true;
      state.currentDrainageTemplate = value
    },
  },
  actions: {
    //driveway templates
    async addDrivewayTemplate({ commit, state }, drivewayTemplate) {
      commit('setIsLoading',true)
      try {
        var template = await api.createDrivewayTemplate(drivewayTemplate)
        if (template)
          state.drivewayTemplates.push(template);
      } finally {
        commit('setIsLoading',false)
      }
    },
    async editDrivewayTemplate({ commit, state }, drivewayTemplate) {
      commit('setIsLoading',true)
      try {
        var template = await api.editDrivewayTemplate(drivewayTemplate)
        if(template){
          var index = state.drivewayTemplates.findIndex(o => o.id == template.id)
          state.drivewayTemplates[index] = template;
        }
      } finally {
        commit('setIsLoading',false)
      }
    },
    async deleteDrivewayTemplate({ commit, state }, drivewayTemplate) {
      commit('setIsLoading',true)
      try {
        var deleted = await api.deleteDrivewayTemplate(drivewayTemplate)
        if(deleted){
          var index = state.drivewayTemplates.findIndex(o => o.id == drivewayTemplate.id)
          state.drivewayTemplates.splice(index, 1);
        }
      } finally {
        commit('setIsLoading',false)
      }
    },

    //grading templates
    async addGradingTemplate({ commit ,state }, gradingTemplate) {
      commit('setIsLoading',true)
      try {
        var template = await api.createLotGradingTemplate(gradingTemplate)
        if(template)
          state.gradingTemplates.push(template);
      } finally {
        commit('setIsLoading',false)
      }
    },
    async editGradingTemplate({ commit, state }, gradingTemplate) {
      commit('setIsLoading',true)
      try {
        var template = await api.editLotGradingTemplate(gradingTemplate)
        if(template){
          var index = state.gradingTemplates.findIndex(o => o.id == template.id)
          state.gradingTemplates[index] = template;
        }
      } finally {
        commit('setIsLoading',false)
      }
    },
    async deleteGradingTemplate({ commit, state }, gradingTemplate) {
      commit('setIsLoading',true)
      try {
        var deleted = await api.deleteLotGradingTemplate(gradingTemplate)
        if(deleted){
          var index = state.gradingTemplates.findIndex(o => o.id == gradingTemplate.id)
          state.gradingTemplates.splice(index, 1);
        }
      } finally {
        commit('setIsLoading',false)
      }
    },
    
    //drainage templates
    async addDrainageTemplate({ commit ,state }, drainageTemplate) {
      commit('setIsLoading',true)
      try {
        var template = await api.createDrainageTemplate(drainageTemplate)
        if(template)
          state.drainageTemplates.push(template);
      } finally {
        commit('setIsLoading',false)
      }
    },
    async editDrainageTemplate({ commit, state }, drainageTemplate) {
      commit('setIsLoading',true)
      try {
        var template = await api.editDrainageTemplate(drainageTemplate)
        if(template){
          var index = state.drainageTemplates.findIndex(o => o.id == template.id)
          state.drainageTemplates[index] = template;
        }
      } finally {
        commit('setIsLoading',false)
      }
    },
    async deleteDrainageTemplate({ commit, state }, drainageTemplate) {
      commit('setIsLoading',true)
      try {
        var deleted = await api.deleteDrainageTemplate(drainageTemplate)
        if(deleted){
          var index = state.drainageTemplates.findIndex(o => o.id == drainageTemplate.id)
          state.drainageTemplates.splice(index, 1);
        }
      } finally {
        commit('setIsLoading',false)
      }
    },
  }
})
<template>
<div class="tabs-container">
    <progress v-if="isLoading"></progress>
    <div class="tabs-headers">
        <div @click="currentTab = 0" class="tab-item" :class="{'active-tab':currentTab==0}">
            <div>Driveway Templates</div>
        </div>
        <div @click="currentTab = 1" class="tab-item" :class="{'active-tab':currentTab==1}">
            <div>Grading Templates</div>
        </div>
        <div @click="currentTab = 2" class="tab-item" :class="{'active-tab':currentTab==2}" style="width: 280px;">
            <div>Drainage Pattern Templates</div>
        </div>
    </div>
    <div class="tab-content">
        <DrivewayTab v-show="currentTab==0"></DrivewayTab>
        <GradingTab v-show="currentTab==1"></GradingTab>
        <DrainageTab v-show="currentTab==2"></DrainageTab>
    </div>
</div>
</template>

<script>
import DrivewayTab from './driveway-template/driveway-tab.vue'
import GradingTab from './grading-template/grading-tab.vue'
import DrainageTab from './drainage-template/drainage-tab.vue'
import { mapState } from "vuex";

export default {
    name: 'TemplatesTable',
    components: {
        DrivewayTab,
        GradingTab,
        DrainageTab
    },
    props: {
    },
    data: () => ({
        currentTab: 0,
    }),
    methods: {
        setTab(index) {
            this.currentTab = index
        }
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.isLoading,
        }),
    }
}
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.tabs-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}

.tabs-headers {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    border-color: #dde2e7;
    border: 1px solid #dde2e7;
    background-color: #f8f9fa;
}

.tab-content {
    flex-grow: 1;
    border: 1px solid #dde2e7;
    border-top: none;
}

.tab-item {
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tab-item:hover {
    background-color: $hover-color;
}

.active-tab {
    border-bottom: 4px solid $main-color;
    color: $main-color;
}

.create-btn {
    position: absolute;
    top: 31px;
    right: 14px;
    width: 146px;
    height: 26px;
    background-color: #f6f6f6;
    border: 1px solid #cbcbcb;
    cursor: pointer;
}

.create-btn:hover {
    background-color: $hover-color;
}

.template-row {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
}

.template-row:hover {
    background-color: $hover-color;
}

.template-title {
    text-align: left;
    flex-grow: 1;
}
.delete-btn{
    color: gray;
}
.delete-btn:hover{
    color:red;
}
progress{
    width: 100%;
    padding: 0;
    margin: 0;
    height: 7px;
}
</style>

<template>
<div>
    <button class="create-btn" @click="createNewTemplate()">Create New Template</button>
    <div class="template-row" v-for="template in drivewayTemplate" :key="template.id" @click="editTemplate(template)">
        <div class="template-title">{{template.name}}</div>
        <div class="delete-btn" @click.stop="deleteTemplate(template)">X</div>
    </div>

    <DrivewayPopup></DrivewayPopup>
</div>
</template>

<script>
import DrivewayPopup from './driveway-popup.vue'
import { mapState } from "vuex";
import utils from '../../services/utils'
import api from '../../api/index'

export default {
    name: 'DrivewayTab',
    components: {
        DrivewayPopup
    },
    data: () => ({}),
    async mounted() {
        this.$store.commit('setIsLoading',true);
        try {
            var drivewatTemplates = await api.fetchDrivewayTemplates();
            this.$store.commit('setDrivewayTemplates', drivewatTemplates)
        } finally {
            this.$store.commit('setIsLoading',false);
        }
    },
    methods: {
        createNewTemplate() {
            var template = {
                id: utils.newGuid(),
                name: 'New Template',
                frontDriveway:{
                    standardMaxSlope: 10,
                    frontSlopes:{
                        aboveSlopeInfo: {slope:{ type: utils.slopeTypes.fixed, value: 16, rangeMin: 0, rangeMax: 0 },length:16} ,
                        middleSlopeInfo: {slope:{ type: utils.slopeTypes.calculated, value: 0, rangeMin: 0, rangeMax: 0 },length:0},
                        belowSlopeInfo: {slope:{ type: utils.slopeTypes.fixed, value: 14, rangeMin: 0, rangeMax: 0 },length:8},
                    }
                },
                sideDriveway:{
                    sideDrivewayTopSlope:{type: utils.slopeTypes.fixed, value: 5, rangeMin: 0, rangeMax: 0},
                },
            }
            this.$store.commit('setDrivewayTemplateToCreate', template)
        },
        editTemplate(template) {
            this.$store.commit('setDrivewayTemplateToEdit', template)
        },
        deleteTemplate(template) {
            if (!confirm("Are you sure you want to delete template?")) {
                return;
            }
            this.$store.dispatch('deleteDrivewayTemplate', template)
        }
    },
    computed: {
        ...mapState({
            drivewayTemplate: (state) => state.drivewayTemplates
        })
    }
}
</script>

<style scoped>

</style>


export default class utils {
    constructor() {
    }
    static slopeTypes={
        fixed: 0,
        lessThan: 1,
        moreThan: 2,
        range: 3,
        calculated: 4
    }
    
    static drainageTypes={
        split: 0,
        backToFront: 1,
        frontToBack: 2,
    }
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        })
    }
    static cloneSlope(slope) {
        return { type: slope.type, value: slope.value, rangeMin: slope.rangeMin, rangeMax: slope.rangeMax }
    }
    static cloneAdvancedSlope(advSlope){
        return { slope:utils.cloneSlope(advSlope.slope), length: advSlope.length }
    }
    static validateSlope(slope) {
        if (slope.type == this.slopeTypes.fixed) {
            if (slope.value < 0) {
                alert('Cannot set slope less than zero.')
                return false;
            }
            if (slope.value > 200) {
                alert('Cannot set slope more than 200%.')
                return false;
            }
        }
        else if (slope.type == this.slopeTypes.lessThan) {
            if (slope.value < 0) {
                alert('Cannot set slope less than zero.')
                return false;
            }
        }
        else if (slope.type == this.slopeTypes.moreThan) {
            if (slope.value > 200) {
                alert('Cannot set slope more than 200%.')
                return false;
            }
        }
        else if (slope.type == this.slopeTypes.range) {
            if (slope.rangeMin < 0) {
                alert('Cannot set slope less than zero.')
                return false;
            }
            if (slope.rangeMax > 200) {
                alert('Cannot set slope more than 200%.')
                return false;
            }
            if (slope.rangeMin == slope.rangeMax) {
                alert('Cannot set slope range with same number.')
                return false;
            }
            if (slope.rangeMin > slope.rangeMax) {
                alert('Minimum slope cannot be more than maximum slope.')
                return false;
            }
        }
        return true;
    }
}
<template>
<div class="row-container">
    <div class="row-title" v-if="title">{{title}}</div>
    <div class="row-right-side">
        <select :value="slope.type" v-model="type" @change="updateSlope()" class="dropdwon-options row-input-item">
            <option :value="0">Fixed</option>
            <option :value="1">Less Than</option>
            <option :value="2">More Than</option>
            <option :value="3">Range</option>
            <option :value="4" v-if="!hideCalculatedOption">Calculated</option>
        </select>
        <input class="row-input-item" type="number" v-model="value" v-if="type!=3&&type!=4" @input="updateSlope()">
        <input class="row-input-item" type="number" v-model="rangeMin" v-if="type==3&&type!=4" @input="updateSlope()">
        <input class="row-input-item" type="number" v-model="rangeMax" v-if="type==3&&type!=4" @input="updateSlope()">
    </div>
</div>
</template>

<script>
export default {
    name: 'SlopeInput',
    props: {
        title: String,
        slope: Object,
        hideCalculatedOption:Boolean,
    },
    data: () => ({
        type: '',
        value: 0,
        rangeMin: 0,
        rangeMax: 0,
    }),
    mounted(){
        this.type = this.slope.type
        this.value = this.slope.value
        this.rangeMin = this.slope.rangeMin
        this.rangeMax = this.slope.rangeMax
    },
    methods: {
        updateSlope() {
            this.$emit('apply-slope', { type: this.type, value: this.value, rangeMin: this.rangeMin, rangeMax: this.rangeMax });
        },
    }
}
</script>

<style scoped>
.row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    height: 40px;
    padding: 5px 25px;
}

.row-title {
    width: 50%;
    text-align: start;
}

.row-right-side {
    width: 53%;
    display: flex;
    flex-direction: row;
    height: 40px;
    flex-grow: 1;
}

.row-input-item {
    flex-grow: 1;
    width: 30%;
    margin: 0 1px;
}

.dropdwon-options {
    flex-grow: 1;
}
</style>

<template>
    <div class="modal-overlay" @click="close()" v-if="currentGradingTemplate">
        <div class="modal-content" @click.stop="" v-if="pageIndex==0">
            <div class="close-btn" @click="close()">X</div>
            <div class="model-header">
                <h3>Grading Template</h3>
            </div>
            <div class="modal-body">
                <div class="navigator-bar">
                    <div class="navigation-step step-1 active-step" @click="pageIndex = 0">
                        <div class="step-name">Profile Name</div>
                    </div>
                    <div class="navigation-step step-2" @click="pageIndex = 1">
                        <div class="step-name">Buffer Region</div>
                    </div>
                    <div class="navigation-step step-3" @click="pageIndex = 2">
                        <div class="step-name">Grading Points</div>
                    </div>
                </div>
                <div class="input-row">
                    <div class="row-title">Profile Name</div>
                    <input class="row-title input-height" type="text" v-model="currentGradingTemplate.name">
                </div>
    
            </div>
            <div class="modal-footer">
                <div @click="next()" class="save-btn input-height">Next</div>
            </div>
        </div>
    
        <div class="modal-content" @click.stop="" v-if="pageIndex==1">
            <div class="close-btn" @click="close()">X</div>
            <div class="model-header">
                <h3>Grading Template</h3>
            </div>
            <div class="modal-body">
                <div class="navigator-bar">
                    <div class="navigation-step step-1" @click="pageIndex = 0">
                        <div class="step-name">Profile Name</div>
                    </div>
                    <div class="navigation-step step-2 active-step" @click="pageIndex = 1">
                        <div class="step-name">Buffer Region</div>
                    </div>
                    <div class="navigation-step step-3" @click="pageIndex = 2">
                        <div class="step-name">Grading Points</div>
                    </div>
                </div>
                <div style="display:flex;">
                    <div style="flex-grow:1;">
                        <div class="input-row">
                            <div class="row-title">Side Buffer Length (S)</div>
                            <input class="row-title input-height" type="number" v-model="currentGradingTemplate.buffers.sideBuffer">
                        </div>
                        <div class="input-row">
                            <div class="row-title">Rear Buffer Length (R)</div>
                            <input class="row-title input-height" type="number" v-model="currentGradingTemplate.buffers.rearBuffer">
                        </div>
                        <div class="input-row">
                            <div class="row-title">Front Buffer Length (F)</div>
                            <input class="row-title input-height" type="number" v-model="currentGradingTemplate.buffers.frontBuffer">
                        </div>
                        <div class="input-row">
                            <div class="row-title">Driveway Buffer Length (D)</div>
                            <input class="row-title input-height" type="number" v-model="currentGradingTemplate.buffers.drivewayBuffer">
                        </div>
                    </div>
                    <img src="@/assets/buffersImg.jpg" height="300" style="margin-right: 20px;">
                </div>
            </div>
            <div class="modal-footer">
                <div @click="next()" class="save-btn input-height">Next</div>
            </div>
        </div>
    
        <div class="modal-content" @click.stop="" v-if="pageIndex==2">
            <div class="close-btn" @click="close()">X</div>
            <div class="model-header">
                <h3>Grading Template</h3>
            </div>
            <div class="modal-body">
                <div class="navigator-bar">
                    <div class="navigation-step step-1" @click="pageIndex = 0">
                        <div class="step-name">Profile Name</div>
                    </div>
                    <div class="navigation-step step-2" @click="pageIndex = 1">
                        <div class="step-name">Buffer Region</div>
                    </div>
                    <div class="navigation-step step-3 active-step" @click="pageIndex = 2">
                        <div class="step-name">Grading Points</div>
                    </div>
                </div>
    
                <div style="display:flex;">
                    <div style="flex-grow:1;">
                        <h4 style="text-align: initial;margin-left: 20px;">Front Exposed Wall</h4>
                        <div class="input-row">
                            <div class="row-title exposed-wall-left">Exposed Wall Min</div>
                            <input class="row-title input-height" type="number" v-model="currentGradingTemplate.minFrontExposedWall">
                        </div>
                        <div class="input-row">
                            <div class="row-title exposed-wall-left">Exposed Wall Max</div>
                            <input class="row-title input-height" type="number" v-model="currentGradingTemplate.maxFrontExposedWall">
                        </div>
                        <h4 style="text-align: initial;margin-left: 20px;">Rear Exposed Wall</h4>
                        <div class="input-row">
                            <div class="row-title exposed-wall-left">Exposed Wall Min</div>
                            <input class="row-title input-height" type="number" v-model="currentGradingTemplate.minRearExposedWall">
                        </div>
                        <div class="input-row">
                            <div class="row-title exposed-wall-left">Exposed Wall Max</div>
                            <input class="row-title input-height" type="number" v-model="currentGradingTemplate.maxRearExposedWall">
                        </div>
                        <div class="input-row">
                            <div class="row-title exposed-wall-left">Rear Exposed Max Slope (%)</div>
                            <input class="row-title" type="number" v-model="currentGradingTemplate.rearExposedMaxSlope">
                        </div>
                        
                        <div class="input-row">
                            <label for="wrappingRearCorners" class="row-title exposed-wall-left">Wrap Rear Corners</label>
                            <input id="wrappingRearCorners" class="row-title" type="checkbox" v-model="currentGradingTemplate.wrappingRearCorners">
                        </div>
    
                        <h4>You can click on any slope to change its value.</h4>
                        <div v-if="slopeToEdit" style="padding:25px;">
                            <SlopeInput :title="'Slope'" :slope="slopeToEdit" @apply-slope="slopeToEdit = $event" :hideCalculatedOption="true"></SlopeInput>
                            <div style="display: flex;">
                                <div @click="applySlope()" class="apply-btn input-height">Apply</div>
                                <div @click="cancelEditing()" class="apply-btn input-height" style="margin-left: 5px;">Cancel</div>
                            </div>
                        </div>
                    </div>
                    <div style="height:500px;position:relative;">
                        <img src="@/assets/gradingPoints.jpg" height="500" style="margin-right: 20px;">
                        <div class="slope-text" style="left: 87px;top: 280px;" @click="editSlope(rightBelowAngle,'rightBelowAngle')">{{getSlopeText(rightBelowAngle)}}</div>
                        <div class="slope-text" style="left: 315px;top: 283px;" @click="editSlope(leftBelowAngle,'leftBelowAngle')">{{getSlopeText(leftBelowAngle)}}</div>
                        
                        <div class="slope-text" style="left: 203px;top: 23px;" @click="editSlope(rearBelowAngle,'rearBelowAngle')">{{getSlopeText(rearBelowAngle)}} (+/-)</div>
                        <div class="slope-text" style="left: 168px;top: 149px;" @click="editSlope(rearBufferAngle,'rearBufferAngle')">{{getSlopeText(rearBufferAngle)}}</div>
                        <div class="slope-text" style="left: 105px;top: 168px;" @click="editSlope(rearRightBufferAboveAngle,'rearRightBufferAboveAngle')">{{getSlopeText(rearRightBufferAboveAngle)}}</div>
                        <div class="slope-text" style="left: 73px;top: 159px;" @click="editSlope(rearRightBufferBelowAngle,'rearRightBufferBelowAngle')">{{getSlopeText(rearRightBufferBelowAngle)}}</div>
                        <div class="slope-text" style="left: 274px;top: 175px;" @click="editSlope(rearLeftBufferAboveAngle,'rearLeftBufferAboveAngle')">{{getSlopeText(rearLeftBufferAboveAngle)}}</div>
                        <div class="slope-text" style="left: 317px;top: 152px;" @click="editSlope(rearLeftBufferBelowAngle,'rearLeftBufferBelowAngle')">{{getSlopeText(rearLeftBufferBelowAngle)}}</div>
                        <div class="slope-text" style="left: 105px;top: 115px;" @click="editSlope(rearRightCornerAboveAngle,'rearRightCornerAboveAngle')">{{getSlopeText(rearRightCornerAboveAngle)}}</div>
                        <div class="slope-text" style="left: 76px;top: 79px;" @click="editSlope(rearRightCornerBelowAngle,'rearRightCornerBelowAngle')">{{getSlopeText(rearRightCornerBelowAngle)}}</div>
                        <div class="slope-text" style="left: 280px;top: 113px;" @click="editSlope(rearLeftCornerAboveAngle,'rearLeftCornerAboveAngle')">{{getSlopeText(rearLeftCornerAboveAngle)}}</div>
                        <div class="slope-text" style="left: 317px;top: 78px;" @click="editSlope(rearLeftCornerBelowAngle,'rearLeftCornerBelowAngle')">{{getSlopeText(rearLeftCornerBelowAngle)}}</div>
                        <div class="slope-text" style="left: 116px;top: 348px;" @click="editSlope(frontRightBelowAngle,'frontRightBelowAngle')">{{getSlopeText(frontRightBelowAngle)}}</div>
                        <div class="slope-text" style="left: 304px;top: 356px;" @click="editSlope(frontLeftBelowAngle,'frontLeftBelowAngle')">{{getSlopeText(frontLeftBelowAngle)}}</div>
                    
                        <div class="slope-text" style="left: 263px;top: 289px;" @click="editSlope(frontLeftCornerSlope,'frontLeftCornerSlope')">{{getSlopeText(frontLeftCornerSlope)}}</div>
                        <div class="slope-text" style="left: 263px;top: 207px;" @click="editSlope(rearLeftCornerSlope,'rearLeftCornerSlope')">{{getSlopeText(rearLeftCornerSlope)}}</div>
                        <div class="slope-text" style="left: 144px;top: 291px;" @click="editSlope(frontRightCornerSlope,'frontRightCornerSlope')">{{getSlopeText(frontRightCornerSlope)}}</div>
                        <div class="slope-text" style="left: 144px;top: 237px;" @click="editSlope(rearRightCornerSlope,'rearRightCornerSlope')">{{getSlopeText(rearRightCornerSlope)}}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div @click="save()" class="save-btn input-height">Save</div>
            </div>
        </div>
    
    </div>
    </template>
    
    <script>
    import utils from '@/services/utils';
    import { mapState } from "vuex";
    import SlopeInput from '../shared/slope-input.vue'
    
    export default {
        name: 'GradingPopup',
        components: {
            SlopeInput
        },
        data: () => ({
            pageIndex: 0,
            slopeNameToEdit: null,
            slopeToEdit: null,
        }),
        methods: {
            editSlope(slope, slopeName) {
                this.slopeToEdit = slope;
                this.slopeNameToEdit = slopeName;
            },
            cancelEditing(){
                this.slopeToEdit = null;
                this.slopeNameToEdit = null;
            },
            getSlopeText(slope) {
                if (slope.type == utils.slopeTypes.fixed)
                    return slope.value + '%'
                else if (slope.type == utils.slopeTypes.lessThan)
                    return `<${slope.value}%`
                else if (slope.type == utils.slopeTypes.moreThan)
                    return `>${slope.value}%`
                else if (slope.type == utils.slopeTypes.range)
                    return `${slope.rangeMin}-${slope.rangeMax}%`
            },
            applySlope() {
                if(!utils.validateSlope(this.slopeToEdit))
                    return;
                if(this.currentGradingTemplate.gradingPoints[this.slopeNameToEdit]){
                    this.currentGradingTemplate.gradingPoints[this.slopeNameToEdit] = this.slopeToEdit;
                }
                else{
                    this.currentGradingTemplate[this.slopeNameToEdit] = this.slopeToEdit;
                }
                this.slopeNameToEdit = null;
                this.slopeToEdit = null;
            },
            next() {
                this.currentGradingTemplate.name = this.currentGradingTemplate.name.trim()
                if (this.pageIndex == 0 && this.validateName(this.currentGradingTemplate)) {
                    this.pageIndex++;
                } else if (this.pageIndex == 1 && this.validateBuffers()) {
                    this.pageIndex++;
                }
            },
            validateName(template) {
                if (!template.name) {
                    alert('Invalid profile name!');
                    return false;
                }
                if (this.gradingTemplates.some(o => o.id != template.id && o.name == template.name)) {
                    alert('Name is already existing, please choose another name!');
                    return false;
                }
                return true;
            },
            validateBuffers() {
                if (this.currentGradingTemplate.buffers.sideBuffer < 0 ||
                    this.currentGradingTemplate.buffers.frontBuffer < 0 ||
                    this.currentGradingTemplate.buffers.rearBuffer < 0 ||
                    this.currentGradingTemplate.buffers.drivewayBuffer < 0) {
                    alert('Buffers cannot be less than zero.');
                    return false
                }
                return true;
            },
            close() {
                this.$store.commit('setGradingTemplateToCreate', null)
                this.pageIndex = 0;
            },
            save() {
                if (!this.validate(this.currentGradingTemplate))
                    return;
                if (this.isCreating)
                    this.$store.dispatch('addGradingTemplate', this.currentGradingTemplate)
                else
                    this.$store.dispatch('editGradingTemplate', this.currentGradingTemplate)
                this.close()
            },
            validate(newTemplate) {
                if (!this.validateName(newTemplate)) {
                    return false;
                }
                if (!this.validateBuffers())
                    return false;
                return true;
            }
        },
        computed: {
            ...mapState({
                gradingTemplates: (state) => state.gradingTemplates,
                currentGradingTemplate: (state) => state.currentGradingTemplate,
                isCreating: (state) => state.isCreating,
    
                rightBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.rightBelowAngle,
                leftBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.leftBelowAngle,
                rearBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.rearBelowAngle,
                rearBufferAngle: (state) => state.currentGradingTemplate.gradingPoints.rearBufferAngle,
                rearRightBufferAboveAngle: (state) => state.currentGradingTemplate.gradingPoints.rearRightBufferAboveAngle,
                rearRightBufferBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.rearRightBufferBelowAngle,
                rearLeftBufferAboveAngle: (state) => state.currentGradingTemplate.gradingPoints.rearLeftBufferAboveAngle,
                rearLeftBufferBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.rearLeftBufferBelowAngle,
                rearRightCornerAboveAngle: (state) => state.currentGradingTemplate.gradingPoints.rearRightCornerAboveAngle,
                rearRightCornerBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.rearRightCornerBelowAngle,
                rearLeftCornerAboveAngle: (state) => state.currentGradingTemplate.gradingPoints.rearLeftCornerAboveAngle,
                rearLeftCornerBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.rearLeftCornerBelowAngle,
                frontRightBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.frontRightBelowAngle,
                frontLeftBelowAngle: (state) => state.currentGradingTemplate.gradingPoints.frontLeftBelowAngle,
                frontLeftCornerSlope: (state) => state.currentGradingTemplate.frontLeftCornerSlope,
                rearLeftCornerSlope: (state) => state.currentGradingTemplate.rearLeftCornerSlope,
                frontRightCornerSlope: (state) => state.currentGradingTemplate.frontRightCornerSlope,
                rearRightCornerSlope: (state) => state.currentGradingTemplate.rearRightCornerSlope,
            }),
        }
    }
    </script>
    
    <style lang="scss" scoped>
    @import '@/scss/variables.scss';
    
    .modal-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #cbcbcb;
        opacity: .8;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .modal-content {
        width: 900px;
        display: flex;
        flex-direction: column;
        position: relative;
        opacity: 1;
        background-color: #F4F4F4;
        border-radius: 22px;
    }
    
    .model-header {
        height: 40px;
        position: relative;
    }
    
    .step-1 {
        left: 0;
    }
    
    .step-2 {
        left: 50%;
    }
    
    .step-3 {
        left: 100%;
    }
    
    .step-name {
        position: absolute;
        left: -34px;
        top: -25px;
        width: 110px;
    }
    
    .active-step {
        border: 2px solid $secondary-color !important;
        color: $secondary-color;
    }
    
    .navigation-step {
        position: absolute;
        top: -10px;
        width: 25px;
        height: 25px;
        border-radius: 30px;
        background-color: $main-color;
        cursor: pointer;
    }
    
    .navigation-step:hover {
        background-color: gray;
    }
    
    .modal-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    
    .body-side {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
    }
    
    .modal-footer {
        height: 60px;
    }
    
    .close-btn {
        position: absolute;
        top: -10px;
        right: 35px;
        border-radius: 16px;
        width: 24px;
        height: 24px;
        background-color: $main-color;
        cursor: pointer;
        padding-top: 3px;
        box-sizing: border-box;
        color: white;
        z-index: 1;
    }
    
    .close-btn:hover {
        background-color: gray;
    }
    
    .input-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 25px;
    }
    
    .row-title {
        width: 50%;
        text-align: start;
    }
    
    .save-btn {
        width: 150px;
        height: 40px;
        border-radius: 50px;
        color: white;
        background-color: #0E0E0E;
        position: absolute;
        right: 20px;
        bottom: 20px;
        padding-top: 7px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .apply-btn {
        width: 150px;
        height: 40px;
        border-radius: 50px;
        color: white;
        background-color: #0E0E0E;
        padding-top: 7px;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    .save-btn:hover , .apply-btn:hover{
        background-color: gray;
    }
    
    .input-height {
        height: 30px;
    }
    
    .slope-text {
        position: absolute;
        font-size: 13px;
        cursor: pointer;
    }
    
    .slope-text:hover {
        color: $secondary-color;
    }
    .exposed-wall-left{
        padding-left: 20px;
        box-sizing: border-box;
        width: 70%;
    }
    </style>
    
<template>
<div>
    <button class="create-btn" @click="createNewTemplate()">Create New Template</button>
    <div class="template-row" v-for="template in gradingTemplates" :key="template.id" @click="editTemplate(template)">
        <div class="template-title">{{template.name}}</div>
        <div class="delete-btn" @click.stop="deleteTemplate(template)">X</div>
    </div>

    <GradingPopup></GradingPopup>
</div>
</template>

<script>
import GradingPopup from './grading-popup.vue'
import { mapState } from "vuex";
import utils from '../../services/utils'
import api from '../../api/index'

export default {
    name: 'GradingTab',
    props: {},
    components: {
        GradingPopup
    },
    async mounted() {
        this.$store.commit('setIsLoading',true);
        try {
            var lotGradingTemplates = await api.fetchLotGradingTemplates();
            this.$store.commit('setGradingTemplates', lotGradingTemplates)
        } finally {
            this.$store.commit('setIsLoading',false);
        }
    },
    methods: {
        createNewTemplate() {
            var template = {
                id: utils.newGuid(),
                name: 'New Template',
                wrappingRearCorners:false,
                minFrontExposedWall:1,
                maxFrontExposedWall:3,
                minRearExposedWall:1,
                maxRearExposedWall:3,
                rearExposedMaxSlope: 10,
                frontLeftCornerSlope: { type: utils.slopeTypes.fixed, value: 0 },
                rearLeftCornerSlope: { type: utils.slopeTypes.fixed, value: 0 },
                frontRightCornerSlope: { type: utils.slopeTypes.fixed, value: 0 },
                rearRightCornerSlope: { type: utils.slopeTypes.fixed, value: 0 },
                buffers: {
                    sideBuffer: 2,
                    frontBuffer: 1,
                    rearBuffer: 10,
                    drivewayBuffer: 2,
                },
                gradingPoints: {
                    rightBelowAngle: { type: utils.slopeTypes.fixed, value: 33 },
                    leftBelowAngle: { type: utils.slopeTypes.fixed, value: 33 },
                    rearBelowAngle: { type: utils.slopeTypes.moreThan, value: 33, rangeMin: 0, rangeMax: 0 },
                    rearBufferAngle: { type: utils.slopeTypes.range, value: 0, rangeMin: 2, rangeMax: 5 },
                    rearRightBufferAboveAngle: { type: utils.slopeTypes.range, value: 0, rangeMin: 2, rangeMax: 33 },
                    rearRightBufferBelowAngle: { type: utils.slopeTypes.moreThan, value: 5, rangeMin: 0, rangeMax: 0 },
                    rearLeftBufferAboveAngle: { type: utils.slopeTypes.range, value: 0, rangeMin: 2, rangeMax: 33 },
                    rearLeftBufferBelowAngle: { type: utils.slopeTypes.moreThan, value: 5, rangeMin: 0, rangeMax: 0 },
                    rearRightCornerAboveAngle: { type: utils.slopeTypes.range, value: 0, rangeMin: 2, rangeMax: 33 },
                    rearRightCornerBelowAngle: { type: utils.slopeTypes.moreThan, value: 5, rangeMin: 0, rangeMax: 0 },
                    rearLeftCornerAboveAngle: { type: utils.slopeTypes.range, value: 0, rangeMin: 2, rangeMax: 33 },
                    rearLeftCornerBelowAngle: { type: utils.slopeTypes.moreThan, value: 5, rangeMin: 0, rangeMax: 0 },
                    frontRightBelowAngle: { type: utils.slopeTypes.moreThan, value: 33, rangeMin: 0, rangeMax: 0 },
                    frontLeftBelowAngle: { type: utils.slopeTypes.moreThan, value: 33, rangeMin: 0, rangeMax: 0 },
                },
            }
            this.$store.commit('setGradingTemplateToCreate', template)
        },
        editTemplate(template) {
            this.$store.commit('setGradingTemplateToEdit', template)
        },
        deleteTemplate(template) {
            if (!confirm("Are you sure you want to delete template?")) {
                return;
            }
            this.$store.dispatch('deleteGradingTemplate', template)
        }
    },
    computed: {
        ...mapState({
            gradingTemplates: (state) => state.gradingTemplates
        })
    }
}
</script>

<style scoped>
</style>

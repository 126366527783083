import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store'
import router from "./router";
import axios from 'axios';
import appConfig from "@/appConfig";

axios.defaults.baseURL = appConfig.apiUrl;
const app = createApp(App).use(store).use(router);
// //add axios in all components
// app.config.globalProperties.$axios = axios;
app.mount("#app")

export default app;
import axios from 'axios';
import authService from '../auth/AuthorizeService';
import appConfig from '../appConfig';

export default {
  async createAxios(cancelTokenSource) {
    if (appConfig.useJwt) {
      const token = await authService.getAccessToken();
      return axios.create({
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: cancelTokenSource && cancelTokenSource.token
      });
    } else {
      return axios.create({
        withCredentials: true,
        cancelToken: cancelTokenSource && cancelTokenSource.token
      });
    }
  },

  //driveway templates
  async fetchDrivewayTemplates() {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.get(`api/driveway-templates/getAll`)
    return response.data;
  },
  async createDrivewayTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.post(`api/driveway-templates/createOne`, template)
    return response.data;
  },
  async editDrivewayTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.put(`api/driveway-templates/update`, template)
    return response.data;
  },
  async deleteDrivewayTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.delete(`api/driveway-templates/${template.id}`)
    return response.data;
  },

  //lot grading template
  async fetchLotGradingTemplates() {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.get(`api/lot-grading-template/getAll`)
    return response.data;
  },
  async createLotGradingTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.post(`api/lot-grading-template/createOne`, template)
    return response.data;
  },
  async editLotGradingTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.put(`api/lot-grading-template/update`, template)
    return response.data;
  },
  async deleteLotGradingTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.delete(`api/lot-grading-template/${template.id}`)
    return response.data;
  },

  
  //lot drainage template
  async fetchDrainageTemplates() {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.get(`api/drainage-template/getAll`)
    return response.data;
  },
  async createDrainageTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.post(`api/drainage-template/createOne`, template)
    return response.data;
  },
  async editDrainageTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.put(`api/drainage-template/update`, template)
    return response.data;
  },
  async deleteDrainageTemplate(template) {
    let axiosInstance = await this.createAxios();
    let response = await axiosInstance.delete(`api/drainage-template/${template.id}`)
    return response.data;
  }
}

import TemplatesTablesPage from './../components/templates-table.vue'
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../auth/Login-Component.vue';
import Logout from '../auth/Logout-Component.vue';
import Unauthorized from "../auth/Unauthorized-Component.vue";
import { ApplicationPaths, LoginActions, LogoutActions } from '../auth/ApiAuthorizationConstants';
import appConfig from "@/appConfig";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: TemplatesTablesPage
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
  {
    path: ApplicationPaths.Login,
    name: "Login",
    component: Login,
    props: {
      action: LoginActions.Login
    }
  },
  {
    path: ApplicationPaths.LoginFailed,
    name: "LoginFailed",
    component: Login,
    props: {
      action: LoginActions.LoginFailed
    }
  },
  {
    path: ApplicationPaths.LoginCallback,
    name: "LoginCallback",
    component: Login,
    props: {
      action: LoginActions.LoginCallback
    }
  },
  {
    path: ApplicationPaths.Profile,
    name: "Profile",
    component: Login,
    props: {
      action: LoginActions.Profile
    }
  },
  {
    path: ApplicationPaths.Register,
    name: "Register",
    component: Login,
    props: {
      action: LoginActions.Register
    }
  },
  {
    path: ApplicationPaths.LogOut,
    name: "LogOut",
    component: Logout,
    props: {
      action: LogoutActions.LogOut
    }
  },
  {
    path: ApplicationPaths.LogOutCallback,
    name: "LogOutCallback",
    component: Logout,
    props: {
      action: LogoutActions.LogOutCallback
    }
  },
  {
    path: ApplicationPaths.LoggedOut,
    name: "LoggedOut",
    component: Logout,
    props: {
      action: LogoutActions.LoggedOut
    }
  },
]

const router = createRouter({ 
  history: createWebHistory(appConfig.appRoot), 
  routes 
});

export default router;
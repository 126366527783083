<template>
<div class="modal-overlay" @click="close()" v-if="currentDrainageTemplate">

    <div class="modal-content" @click.stop="">
        <div class="close-btn" @click="close()">X</div>
        <div class="model-header">
            <h3>Drainage Pattern Template</h3>
        </div>
        <div class="modal-body">
            <div style="display:flex;">
                <div style="flex-grow:1;padding-top: 40px;">
                    <div class="input-row">
                        <div class="row-title">Profile Name</div>
                        <input class="row-title input-height" type="text" v-model="currentDrainageTemplate.name">
                    </div>
                    <div class="input-row">
                        <div class="row-title">Right Side Type</div>
                        <select v-model="rightSideType" class="row-title input-height">
                            <option value="0">Split</option>
                            <option value="1">Back To Front</option>
                            <option value="2">Front To Back</option>
                        </select>
                    </div>
                    <div class="input-row">
                        <div class="row-title">Left Side Type</div>
                        <select v-model="leftSideType" class="row-title input-height">
                            <option value="0">Split</option>
                            <option value="1">Back To Front</option>
                            <option value="2">Front To Back</option>
                        </select>
                    </div>

                    <h4>You can click on any slope to change its value.</h4>
                    <div v-if="slopeToEdit" style="padding:25px;">
                        <SlopeInput :title="'Slope'" :slope="slopeToEdit" @apply-slope="slopeToEdit = $event" :hideCalculatedOption="true"></SlopeInput>
                        <div @click="applySlope()" class="apply-btn input-height">Apply</div>
                    </div>
                </div>
                <div style="height:500px;position:relative;">
                    <img src="@/assets/emptyLot.jpg" height="500" style="margin-right: 20px;">
                    
                    <!------- Split ------->
                    <!-- Right side -->
                    <img src="@/assets/arrow.png" v-if="rightSideType==0" style="top: 280px;" @click="editSlope(rightSideBackToFrontSlope,'rightSide')" class="side-arrow right-back-front-arrow" width="50">
                    <div class="slope-text" style="left: 79px;top: 280px;color: blue;" v-if="rightSideType==0" @click="editSlope(rightSideBackToFrontSlope,'rightSide','backToFrontSlope')">{{getSlopeText(rightSideBackToFrontSlope)}}</div>
                    <img src="@/assets/arrow.png" v-if="rightSideType==0" style="top: 238px;left: 49px;" @click="editSlope(rightSideFrontToBackSlope,'rightSide')" class="side-arrow right-front-back-arrow" width="50">
                    <div class="slope-text" style="left: 79px;top: 238px;color: blue;" v-if="rightSideType==0" @click="editSlope(rightSideFrontToBackSlope,'rightSide','frontToBackSlope')">{{getSlopeText(rightSideFrontToBackSlope)}}</div>
                    <!-- Left side -->
                    <img src="@/assets/arrow.png" v-if="leftSideType==0" style="top: 280px;" @click="editSlope(leftSideBackToFrontSlope,'leftSide','backToFrontSlope')" class="side-arrow left-back-front-arrow" width="70">
                    <div class="slope-text" style="left: 235px;top: 280px;color: blue;" v-if="leftSideType==0" @click="editSlope(leftSideBackToFrontSlope,'leftSide','backToFrontSlope')">{{getSlopeText(leftSideBackToFrontSlope)}}</div>
                    <img src="@/assets/arrow.png" v-if="leftSideType==0" style="top: 222px;left: 226px;" @click="editSlope(leftSideFrontToBackSlope,'leftSide','frontToBackSlope')" class="side-arrow left-front-back-arrow" width="70">
                    <div class="slope-text" style="left: 235px;top: 222px;color: blue;" v-if="leftSideType==0" @click="editSlope(leftSideFrontToBackSlope,'leftSide','frontToBackSlope')">{{getSlopeText(leftSideFrontToBackSlope)}}</div>


                    <!------- Back to front ------->
                    <!-- Right side -->
                    <img src="@/assets/arrow.png" v-if="rightSideType==1" @click="editSlope(rightSideBackToFrontSlope,'rightSide')" class="side-arrow right-back-front-arrow" width="70">
                    <div class="slope-text" style="left: 95px;top: 266px;color: blue;" v-if="rightSideType==1" @click="editSlope(rightSideBackToFrontSlope,'rightSide','backToFrontSlope')">{{getSlopeText(rightSideBackToFrontSlope)}}</div>
                    <!-- back to front -->
                    <img src="@/assets/arrow.png" v-if="leftSideType==1" @click="editSlope(leftSideBackToFrontSlope,'leftSide','backToFrontSlope')" class="side-arrow left-back-front-arrow" width="70">
                    <div class="slope-text" style="left: 235px;top: 266px;color: blue;" v-if="leftSideType==1" @click="editSlope(leftSideBackToFrontSlope,'leftSide','backToFrontSlope')">{{getSlopeText(leftSideBackToFrontSlope)}}</div>

                    <!------- Front tO back ------->
                    <!-- Right side -->
                    <img src="@/assets/arrow.png" v-if="rightSideType==2" @click="editSlope(rightSideFrontToBackSlope,'rightSide')" class="side-arrow right-front-back-arrow" width="70">
                    <div class="slope-text" style="left: 95px;top: 266px;color: blue;" v-if="rightSideType==2" @click="editSlope(rightSideFrontToBackSlope,'rightSide','frontToBackSlope')">{{getSlopeText(rightSideFrontToBackSlope)}}</div>
                    <!-- Left side -->
                    <img src="@/assets/arrow.png" v-if="leftSideType==2" @click="editSlope(leftSideFrontToBackSlope,'leftSide','frontToBackSlope')" class="side-arrow left-front-back-arrow" width="70">
                    <div class="slope-text" style="left: 235px;top: 245px;color: blue;" v-if="leftSideType==2" @click="editSlope(leftSideFrontToBackSlope,'leftSide','frontToBackSlope')">{{getSlopeText(leftSideFrontToBackSlope)}}</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div @click="save()" class="save-btn input-height">Save</div>
        </div>
    </div>

</div>
</template>

<script>
import utils from '@/services/utils';
import { mapState } from "vuex";
import SlopeInput from '../shared/slope-input.vue'

export default {
    name: 'DrainagePopup',
    components: {
        SlopeInput
    },
    data: () => ({
        slopeNameToEdit: null,
        slopeSideToEdit: null,
        slopeToEdit: null,
    }),
    methods: {
        editSlope(slope,slopeSideToEdit, slopeName) {
            this.slopeToEdit = slope;
            this.slopeNameToEdit = slopeName;
            this.slopeSideToEdit = slopeSideToEdit;
        },
        getSlopeText(slope) {
            if (slope.type == utils.slopeTypes.fixed)
                return slope.value + '%'
            else if (slope.type == utils.slopeTypes.lessThan)
                return `<${slope.value}%`
            else if (slope.type == utils.slopeTypes.moreThan)
                return `>${slope.value}%`
            else if (slope.type == utils.slopeTypes.range)
                return `${slope.rangeMin}-${slope.rangeMax}%`
        },
        applySlope() {
            if(!utils.validateSlope(this.slopeToEdit))
                return;
            this.currentDrainageTemplate[this.slopeSideToEdit][this.slopeNameToEdit] = this.slopeToEdit
            this.slopeNameToEdit = null;
            this.slopeToEdit = null;
        },
        validateName(template) {
            if (!template.name) {
                alert('Invalid profile name!');
                return false;
            }
            if (this.drainageTemplates.some(o => o.id != template.id && o.name == template.name)) {
                alert('Name is already existing, please choose another name!');
                return false;
            }
            return true;
        },
        close() {
            this.$store.commit('setDrainageTemplateToCreate', null)
        },
        save() {
            if (!this.validate(this.currentDrainageTemplate))
                return;
            if (this.isCreating)
                this.$store.dispatch('addDrainageTemplate', this.currentDrainageTemplate)
            else
                this.$store.dispatch('editDrainageTemplate', this.currentDrainageTemplate)
            this.close()
        },
        validate(newTemplate) {
            if (!this.validateName(newTemplate)) {
                return false;
            }
            return true;
        }
    },
    computed: {
        ...mapState({
            drainageTemplates: (state) => state.drainageTemplates,
            currentDrainageTemplate: (state) => state.currentDrainageTemplate,
            isCreating: (state) => state.isCreating,
            rightSideFrontToBackSlope: (state) => state.currentDrainageTemplate.rightSide.frontToBackSlope,
            rightSideBackToFrontSlope: (state) => state.currentDrainageTemplate.rightSide.backToFrontSlope,
            leftSideFrontToBackSlope: (state) => state.currentDrainageTemplate.leftSide.frontToBackSlope,
            leftSideBackToFrontSlope: (state) => state.currentDrainageTemplate.leftSide.backToFrontSlope,
        }),
        rightSideType: {
            get() {
                return this.$store.state.currentDrainageTemplate.rightSide.type;
            },
            set(val) {
                this.$store.state.currentDrainageTemplate.rightSide.type = parseInt(val);
            },
        },
        leftSideType: {
            get() {
                return this.$store.state.currentDrainageTemplate.leftSide.type;
            },
            set(val) {
                this.$store.state.currentDrainageTemplate.leftSide.type = parseInt(val);
            },
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #cbcbcb;
    opacity: .8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    width: 900px;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 1;
    background-color: #F4F4F4;
    border-radius: 22px;
}

.model-header {
    height: 40px;
    position: relative;
}

.modal-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.modal-footer {
    height: 60px;
}

.close-btn {
    position: absolute;
    top: -10px;
    right: 35px;
    border-radius: 16px;
    width: 24px;
    height: 24px;
    background-color: $main-color;
    cursor: pointer;
    padding-top: 3px;
    box-sizing: border-box;
    color: white;
    z-index: 1;
}

.close-btn:hover {
    background-color: gray;
}

.input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 25px;
}

.row-title {
    width: 50%;
    text-align: start;
}

.save-btn {
    width: 150px;
    height: 40px;
    border-radius: 50px;
    color: white;
    background-color: #0E0E0E;
    position: absolute;
    right: 20px;
    bottom: 20px;
    padding-top: 7px;
    box-sizing: border-box;
    cursor: pointer;
}
.apply-btn {
    width: 150px;
    height: 40px;
    border-radius: 50px;
    color: white;
    background-color: #0E0E0E;
    padding-top: 7px;
    box-sizing: border-box;
    cursor: pointer;
}

.save-btn:hover , .apply-btn:hover{
    background-color: gray;
}

.input-height {
    height: 30px;
}

.slope-text {
    position: absolute;
    font-size: 13px;
    cursor: pointer;
}

.slope-text:hover {
    color: $secondary-color;
}
.side-arrow{
    position: absolute;
    cursor: pointer;
}
.right-back-front-arrow{
    transform: rotate(101deg);
    left: 50px;
    top: 265px;
}
.right-front-back-arrow{
    transform: rotate(-107deg);
    left: 50px;
    top: 265px;
}
.left-back-front-arrow{
    transform: rotate(80deg);
    left: 225px;
    top: 250px;
}
.left-front-back-arrow{
    transform: rotate(-80deg);
    left: 225px;
    top: 250px;
}
</style>

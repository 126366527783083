<template>
<div class="modal-overlay" @click="close()" v-if="currentDrivewayTemplate">
    <div class="modal-content" @click.stop="" v-if="pageIndex==0">
        <div class="close-btn" @click="close()">X</div>
        <div class="model-header">
            <h3>Driveway Template</h3>
        </div>
        <div class="modal-body">
            <div class="navigator-bar">
                <div class="navigation-step step-1 active-step" @click="pageIndex = 0">
                    <div class="step-name">Profile Name</div>
                </div>
                <div class="navigation-step step-2" @click="pageIndex = 1">
                    <div class="step-name">Front Driveway</div>
                </div>
                <div class="navigation-step step-3" @click="pageIndex = 2">
                    <div class="step-name">Side Driveway</div>
                </div>
            </div>
            <div class="input-row">
                <div class="row-title">Profile Name</div>
                <input class="row-title input-height" type="text" v-model="currentDrivewayTemplate.name">
            </div>

        </div>
        <div class="modal-footer">
            <div @click="next()" class="save-btn input-height">Next</div>
        </div>
    </div>
    <div class="modal-content" @click.stop="" v-if="pageIndex==1">
        <div class="close-btn" @click="close()">X</div>
        <div class="model-header">
            <h3>Driveway Template</h3>
        </div>
        <div class="modal-body">
            <div class="navigator-bar">
                <div class="navigation-step step-1" @click="pageIndex = 0">
                    <div class="step-name">Profile Name</div>
                </div>
                <div class="navigation-step step-2 active-step" @click="pageIndex = 1">
                    <div class="step-name">Front Driveway</div>
                </div>
                <div class="navigation-step step-3" @click="pageIndex = 2">
                    <div class="step-name">Side Driveway</div>
                </div>
            </div>
            <div style="display: flex;">
                <div class="body-side">
                    <div class="input-row" style="margin-top: 20px;">
                        <div class="row-title">Standard Max Slope (X%)</div>
                        <input class="row-title input-height" type="number" v-model="currentDrivewayTemplate.frontDriveway.standardMaxSlope">
                    </div>
    
                    <div style="flex-grow:1;min-height:100px"></div>
                    <SlopeInput :title="'Advanced Slope (A%)'" :slope="currentDrivewayTemplate.frontDriveway.frontSlopes.aboveSlopeInfo.slope" @apply-slope="currentDrivewayTemplate.frontDriveway.frontSlopes.aboveSlopeInfo.slope = $event"></SlopeInput>
                    <div class="input-row">
                        <div class="row-title">Length of (A)</div>
                        <input class="row-title input-height" type="number" v-model="currentDrivewayTemplate.frontDriveway.frontSlopes.aboveSlopeInfo.length" :disabled="currentDrivewayTemplate.frontDriveway.frontSlopes.aboveSlopeInfo.slope.type==4">
                    </div>
                    <SlopeInput :title="'Advanced Slope (B%)'" :slope="currentDrivewayTemplate.frontDriveway.frontSlopes.middleSlopeInfo.slope" @apply-slope="currentDrivewayTemplate.frontDriveway.frontSlopes.middleSlopeInfo.slope = $event"></SlopeInput>
                    <div class="input-row">
                        <div class="row-title">Length of (B)</div>
                        <input class="row-title input-height" type="number" v-model="currentDrivewayTemplate.frontDriveway.frontSlopes.middleSlopeInfo.length" :disabled="currentDrivewayTemplate.frontDriveway.frontSlopes.middleSlopeInfo.slope.type==4">
                    </div>
                    <SlopeInput :title="'Advanced Slope (C%)'" :slope="currentDrivewayTemplate.frontDriveway.frontSlopes.belowSlopeInfo.slope" @apply-slope="currentDrivewayTemplate.frontDriveway.frontSlopes.belowSlopeInfo.slope = $event"></SlopeInput>
                    <div class="input-row">
                        <div class="row-title">Length of (C)</div>
                        <input class="row-title input-height" type="number" v-model="currentDrivewayTemplate.frontDriveway.frontSlopes.belowSlopeInfo.length" :disabled="currentDrivewayTemplate.frontDriveway.frontSlopes.belowSlopeInfo.slope.type==4">
                    </div>
                </div>
                <div class="body-side">
                    <img src="@/assets/standardDriveway.jpg" style="margin:5px">
                    <div>Standard Driveway</div>
                    <img src="@/assets/advancedDriveway.jpg" style="margin:5px">
                    <div>Advanced Driveway</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div @click="next()" class="save-btn input-height">Next</div>
        </div>
    </div>
    <div class="modal-content" @click.stop="" v-if="pageIndex==2">
        <div class="close-btn" @click="close()">X</div>
        <div class="model-header">
            <h3>Driveway Template</h3>
        </div>
        <div class="modal-body">
            <div class="navigator-bar">
                <div class="navigation-step step-1" @click="pageIndex = 0">
                    <div class="step-name">Profile Name</div>
                </div>
                <div class="navigation-step step-2" @click="pageIndex = 1">
                    <div class="step-name">Front Driveway</div>
                </div>
                <div class="navigation-step step-3 active-step" @click="pageIndex = 2">
                    <div class="step-name">Side Driveway</div>
                </div>
            </div>
            <div style="display: flex;">
                <div class="body-side">
                    <!-- add side sloe here -->
                    <SlopeInput :title="'Side Slope (X)'" :slope="currentDrivewayTemplate.sideDriveway.sideDrivewayTopSlope" @apply-slope="currentDrivewayTemplate.sideDriveway.sideDrivewayTopSlope = $event"></SlopeInput>
    
                    <div style="flex-grow:1;min-height:25px"></div>
                    <div>A/B/C Slopes are coming from front driveway.</div>
                </div>
                <div class="body-side">
                    <img src="@/assets/sideDriveway.jpg" style="margin:5px;object-fit: contain;" height="400">
                    <div>Side Driveway</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div @click="save()" class="save-btn input-height">Save</div>
        </div>
    </div>

</div>
</template>

<script>
import utils from '@/services/utils';
import { mapState } from "vuex";
import SlopeInput from '../shared/slope-input.vue'
export default {
    name: 'DrivewayPopup',
    components: {
        SlopeInput
    },
    data: () => ({
        pageIndex:0,
    }),
    methods: {
        close() {
            this.$store.commit('setDrivewayTemplateToCreate', null);
            this.pageIndex = 0;
        },
        next() {
            this.currentDrivewayTemplate.name = this.currentDrivewayTemplate.name.trim()
            if (this.pageIndex == 0) {
                this.pageIndex++;
            } else if (this.pageIndex == 1) {
                this.pageIndex++;
            }
        },
        save() {
            if (!this.validate(this.currentDrivewayTemplate))
                return;
            if (this.isCreating)
                this.$store.dispatch('addDrivewayTemplate', this.currentDrivewayTemplate)
            else
                this.$store.dispatch('editDrivewayTemplate', this.currentDrivewayTemplate)
            this.close()
        },
        validate(newTmeplate) {
            if (!newTmeplate.name) {
                alert('Invalid profile name!');
                return false;
            }
            if (this.drivewayTemplate.some(o => o.id != this.currentDrivewayTemplate.id && o.name == newTmeplate.name)) {
                alert('Name is already existing, please choose another name!');
                return false;
            }
            if (newTmeplate.frontDriveway.standardMaxSlope == 0) {
                alert('Standad slope cammot be zero.');
                return false;
            }
            if(!this.validateFrontSlopes(newTmeplate.frontDriveway.frontSlopes)){
                return;
            }
            return true;
        },
        validateFrontSlopes(frontSlopes){
            var slopes = [frontSlopes.aboveSlopeInfo.slope, frontSlopes.belowSlopeInfo.slope, frontSlopes.middleSlopeInfo.slope]
            if (slopes.filter(o => o.type == utils.slopeTypes.calculated).length != 1) {
                alert('There must be one calculated angle in driveway angles.');
                return false;
            }
            var rangedAngles = slopes.filter(o => o.type == 'range')
            if (rangedAngles.some(o => o.rangeMin >= o.rangeMax)) {
                alert('Max range angles must be more than min range angles.');
                return false;
            }
            return true;
        }
    },
    computed: {
        ...mapState({
            drivewayTemplate: (state) => state.drivewayTemplates,
            currentDrivewayTemplate: (state) => state.currentDrivewayTemplate,
            isCreating: (state) => state.isCreating,
        }),
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #cbcbcb;
    opacity: .8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    width: 900px;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 1;
    background-color: #F4F4F4;
    border-radius: 22px;
}

.model-header {
    height: 40px;
    position: relative;
}

.step-1 {
    left: 0;
}

.step-2 {
    left: 50%;
}

.step-3 {
    left: 100%;
}

.step-name {
    position: absolute;
    left: -34px;
    top: -25px;
    width: 110px;
}

.active-step {
    border: 2px solid $secondary-color !important;
    color: $secondary-color;
}

.navigation-step {
    position: absolute;
    top: -10px;
    width: 25px;
    height: 25px;
    border-radius: 30px;
    background-color: $main-color;
    cursor: pointer;
}

.navigation-step:hover {
    background-color: gray;
}

.modal-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.body-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}

.modal-footer {
    height: 60px;
}

.close-btn {
    position: absolute;
    top: -10px;
    right: 35px;
    border-radius: 16px;
    width: 24px;
    height: 24px;
    background-color: $main-color;
    cursor: pointer;
    padding-top: 3px;
    box-sizing: border-box;
    color: white;
    z-index: 1;
}

.close-btn:hover {
    background-color: gray;
}

.input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 25px;
}

.row-title {
    width: 50%;
    text-align: start;
}

.save-btn {
    width: 150px;
    height: 40px;
    border-radius: 50px;
    color: white;
    background-color: #0E0E0E;
    position: absolute;
    right: 20px;
    bottom: 20px;
    padding-top: 7px;
    box-sizing: border-box;
    cursor: pointer;
}
.apply-btn {
    width: 150px;
    height: 40px;
    border-radius: 50px;
    color: white;
    background-color: #0E0E0E;
    padding-top: 7px;
    box-sizing: border-box;
    cursor: pointer;
}

.save-btn:hover , .apply-btn:hover{
    background-color: gray;
}

.input-height {
    height: 30px;
}

.slope-text {
    position: absolute;
    font-size: 13px;
    cursor: pointer;
}

.slope-text:hover {
    color: $secondary-color;
}
.side-arrow{
    position: absolute;
    cursor: pointer;
}
</style>

<template>
    <router-view />
</template>

<script>
import { QueryParameterNames } from './auth/ApiAuthorizationConstants';
import authService from "./auth/AuthorizeService";
import router from "./router";

export default {
  name: 'App',
  async beforeMount() {
    let isAuth = await authService.isAuthenticated();
    if (!isAuth) {
      router.push(`/authentication/login?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(window.location.href)}`);
    } 
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;

}
html{
    height: 100%;
    width: 100%;
}
body{
    height: 100%;
    width: 100%;
    margin: 0;
}

.navigator-bar {
    width: 75%;
    height: 2px;
    background-color: gray;
    margin: 50px 0 25px 0;
    margin-left: 12%;
    position: relative;
}

</style>

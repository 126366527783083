<template>
<div>
    <button class="create-btn" @click="createNewTemplate()">Create New Template</button>
    <div class="template-row" v-for="template in drainageTemplates" :key="template.id" @click="editTemplate(template)">
        <div class="template-title">{{template.name}}</div>
        <div class="delete-btn" @click.stop="deleteTemplate(template)">X</div>
    </div>

    <DrainagePopup></DrainagePopup>
</div>
</template>

<script>
import DrainagePopup from './drainage-popup.vue'
import { mapState } from "vuex";
import utils from '../../services/utils'
import api from '../../api/index'

export default {
    name: 'DrainageTab',
    props: {},
    components: {
        DrainagePopup
    },
    async mounted() {
        this.$store.commit('setIsLoading',true);
        try {
            var drainageTemplates = await api.fetchDrainageTemplates();
            this.$store.commit('setDrainageTemplates', drainageTemplates)
        } finally {
            this.$store.commit('setIsLoading',false);
        }
    },
    methods: {
        createNewTemplate() {
            var template = {
                id: utils.newGuid(),
                name: 'New Template',
                leftSide: {
                    type: utils.drainageTypes.frontToBack,
                    frontToBackSlope: { type: utils.slopeTypes.fixed, value: 0, rangeMin: 0, rangeMax: 0 },
                    backToFrontSlope: { type: utils.slopeTypes.fixed, value: 0, rangeMin: 0, rangeMax: 0 }
                },
                rightSide: {
                    type: utils.drainageTypes.frontToBack,
                    frontToBackSlope: { type: utils.slopeTypes.fixed, value: 0, rangeMin: 0, rangeMax: 0 },
                    backToFrontSlope: { type: utils.slopeTypes.fixed, value: 0, rangeMin: 0, rangeMax: 0 }
                },
            }
            this.$store.commit('setDrainageTemplateToCreate', template)
        },
        editTemplate(template) {
            this.$store.commit('setDrainageTemplateToEdit', template)
        },
        deleteTemplate(template) {
            if (!confirm("Are you sure you want to delete template?")) {
                return;
            }
            this.$store.dispatch('deleteDrainageTemplate', template)
        }
    },
    computed: {
        ...mapState({
            drainageTemplates: (state) => state.drainageTemplates
        })
    }
}
</script>

<style scoped>
</style>
